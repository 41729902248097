<template lang="pug">
  main#main.account-login.d-flex.flex-grow-1.justify-content-center
    b-container
      b-row
        b-col.mx-auto(sm='12' md='10' lg='8' xl='6')
          section.l-section
            .l-section-h
              b-card.card-transparent
                .pb-5.text-center
                  .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')
                  .h4.m-0.mt-2.text-uppercase.text-white
                    | {{ $wc.conf.base.name }}

                wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

                b-form.form-wc.form-login.mt-4(
                  @submit.stop.prevent='formSubmit'
                  @input.stop.prevent='formChange'
                  @reset.stop.prevent='formReset')

                  transition(name='fade')
                    div(v-if='formShow')
                      b-form-group#form-account-email.form-input-icon(
                        :label="$t('session.placeholders.email')"
                        label-for='account-email'
                        label-sr-only)
                        i.input-icon.text-primary.fal.fa-fw.fa-user
                        b-form-input#account-email.form-input-first(
                          name='email'
                          type='email'
                          autocomplete='email'
                          :placeholder="$t('session.placeholders.email')"
                          :state='formValidationState($v.form.data.attributes.email)'
                          @focus.native='formChange'
                          v-model='$v.form.data.attributes.email.$model'
                          trim)

                      b-form-group#form-account-password.form-input-icon(
                        :label="$t('session.placeholders.password')"
                        label-for='account-password'
                        label-sr-only)
                        i.input-icon.text-primary.fal.fa-fw.fa-lock
                        b-form-input#account-password.form-input-last(
                          name='password'
                          type='password'
                          autocomplete='password'
                          :placeholder="$t('session.placeholders.password')"
                          :state='formValidationState($v.form.data.attributes.password)'
                          @focus.native='formChange'
                          v-model='$v.form.data.attributes.password.$model'
                          trim)

                      b-button.mt-4(
                        type='submit'
                        variant='secondary'
                        :disabled='$v.form.data.$invalid'
                        block)
                        i.fal.fa-fw.fa-sign-in
                        |  {{ $t('session.sign_in') }}

                //-.mt-5.help.text-center.small
                  b-link(:to="{ name: 'register' }")
                    span.text-body
                      i.fal.fa-fw.fa-question-circle
                      |  {{ $t('session.help.sign_up') }}
                    span.text-capitalize
                      |  {{ $t('session.sign_up') }}

                //-.mt-2.help.text-center.small
                  b-link.text-body(v-b-toggle.collapse-help)
                    i.fal.fa-fw.fa-question-circle
                    |  {{ $t('session.help.general') }}

                  b-collapse#collapse-help.mt-3
                    b-list-group.list-group-flush.rounded-wc.shadow
                      b-list-group-item(:to="{ name: 'account-password-new' }")
                          | {{ $t('session.help.forgot_password') }}
                      b-list-group-item(:to="{ name: 'account-confirmation-new' }")
                          | {{ $t('session.help.confirmation') }}
                      b-list-group-item(:to="{ name: 'account-unlock-new' }")
                          | {{ $t('session.help.unlock') }}

                loading(backgroundRadius='2rem' :active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import { Form } from '@common/form'
import apiAccount from '@services/api/account'
import { regExp } from '@common/form/validations'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'account-login',
  mixins: [Form],
  computed: {
    apiParams() {
      return {
        init: [],
        login: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  methods: {
    apiCallback(callback) {
      if (callback === 'submit' && this.apiRequest.promise) {
        this.apiRequest.promise
          .then((response) => this.loginSuccess(response))
          .catch(() => this.loginError())
      }
    },
    loginSuccess(response) {
      this.$store.dispatch('auth/login', response.data)
    },
    loginError() {
      this.$store.dispatch('auth/logout')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            email: {
              required,
              regExp: regExp(this.$wc.conf.regex.email_browser),
              maxLength: maxLength(this.$wc.conf.limits.max_email),
              remote: () => this.formRemoteValid('email'),
            },
            password: {
              required,
              minLength: minLength(this.$wc.conf.limits.min_password),
              maxLength: maxLength(this.$wc.conf.limits.max_password),
              remote: () => this.formRemoteValid('password'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiAccount,
      apiModel: 'account',
      apiRequestFill: 'init',
      formBootstrapDefaults: {
        register: { data: { attributes: { remember_me: this.$wc.conf.live.is_mobile } } },
      },
    }
  },
}
</script>
